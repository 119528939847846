import React, { useState } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { FaCommentDots } from "react-icons/fa";

const ChatbotComponent = () => {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false); // Track if the chatbot is open

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen); // Toggle chatbot open state
  };

  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#25D366",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#25D366",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  const steps = [
    {
      id: "1",
      message: "Hello! How can I assist you today?",
      trigger: "user-options",
    },
    {
      id: "user-options",
      options: [
        {
          value: "services",
          label: "Tell me about services",
          trigger: "services",
        },
        { value: "contact", label: "How to contact you?", trigger: "contact" },
        {
          value: "gallery",
          label: "Show me the gallery",
          trigger: "gallery-options",
        },
      ],
    },
    {
      id: "services",
      message:
        "Chilli Export , Chilli Import , Custom Packaging , Bulk Supply , Quality Control , Market Insights",
      end: false,
    },
    {
      id: "contact",
      options: [
        {
          value: "phone",
          label: "Phone",
          trigger: "phone-number",
        },
        {
          value: "email",
          label: "Email",
          trigger: "email-ids",
        },
      ],
      // message:
      //   "Phone No: 9087624545,8680907810 EmailIDs: vsrgloballink@gmail.com",
      // end: false,
    },
    {
      id: "phone-number",
      message: "Phone No: 9087624545, 8680907810",
      trigger: "contact",
      // end: false,
    },
    {
      id: "email-ids",
      message: "Email IDs : vsrgloballink@gmail.com",
      trigger: "contact",
      //end: false,
    },
    {
      id: "gallery-options",
      message: "Here are our chilli varieties. Please select one to know more:",
      trigger: "gallery-list",
    },
    {
      id: "gallery-list",
      options: [
        {
          value: "sannam",
          label: "Dry Red Chillies - SANNAM",
          trigger: "sannam",
        },
        { value: "teja", label: "Dry Red Chillies - TEJA", trigger: "teja" },
        {
          value: "wrinkle",
          label: "Dry Red Chillies - 273 WRINKLE",
          trigger: "wrinkle",
        },
        {
          value: "byadgi",
          label: "Dry Red Chillies - BYADGI",
          trigger: "byadgi",
        },
      ],
    },
    {
      id: "sannam",
      message:
        "Dry Red Chillies - SANNAM: Medium heat, vibrant color, popular in Asian cuisine.",
      trigger: "more-gallery-options",
    },
    {
      id: "teja",
      message:
        "Dry Red Chillies - TEJA: Known for its high heat and bright red color.",
      trigger: "more-gallery-options",
    },
    {
      id: "wrinkle",
      message:
        "Dry Red Chillies - 273 WRINKLE: Less spicy, suitable for milder dishes.",
      trigger: "more-gallery-options",
    },
    {
      id: "byadgi",
      message:
        "Dry Red Chillies - BYADGI: Rich red color, low heat, perfect for vibrant color in dishes.",
      trigger: "more-gallery-options",
    },
    {
      id: "more-gallery-options",
      message: "Would you like to learn more about another variety?",
      trigger: "gallery-list", // Go back to the gallery list
    },
  ];

  return (
    <div className="chatbot-container">
      {!isChatbotOpen && (
        <button style={{ border: "none", borderRadius: "40%" }}>
          <FaCommentDots
            className="chatbot-icon"
            onClick={toggleChatbot}
            size={50}
            color="#25D366"
          />{" "}
          {/* Message icon */}
        </button>
      )}
      {isChatbotOpen && (
        <div className="chatbot-content">
          <button className="close-chatbot" onClick={toggleChatbot}>
            ✖ {/* Close button */}
          </button>
          <ThemeProvider theme={theme}>
            <ChatBot steps={steps} />
          </ThemeProvider>
        </div>
      )}
    </div>
    // <div className="chatbot-container">
    //   <ThemeProvider theme={theme}>
    //     <ChatBot steps={steps} />
    //   </ThemeProvider>
    // </div>
  );
};

export default ChatbotComponent;
