import React from "react";
import { CardBody, Card, CardHeader } from "reactstrap";

const Sannam = (prop) => {
  const { product } = prop;

  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Products Descriptions</h2>
          <h5>Explore our collection of premium dry red chillies</h5>
        </div>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="ribbon card-title mb-0 flex-grow-1">
              {product.title ? product.title : "Select Product"}
            </h4>
          </CardHeader>
          <CardBody>
            {product.title === "Dry Red Chillies - BYADGI" ? (
              <table
                style={{ alignContent: "center" }}
                className="table table-bordered"
              >
                <tbody>
                  <tr>
                    <th scope="row">Chilli Type Available</th>
                    <td>
                      {product.productDetails["Chilli Type Available"] || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Packaging Type Available</th>
                    <td>
                      {product.productDetails["Packaging Type Available"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Feature</th>
                    <td>
                      {product.productDetails["Feature"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table
                style={{ alignContent: "center" }}
                className="table table-bordered"
              >
                <tbody>
                  <tr>
                    <th scope="row">COLOUR</th>
                    <td>{product.productDetails["COLOUR"] || "N/A"}</td>
                  </tr>
                  <tr>
                    <th scope="row">VARIETY</th>
                    <td>
                      {product.productDetails["VARIETY"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">TYPE</th>
                    <td>
                      {product.productDetails["TYPE"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">PODS</th>
                    <td>
                      {product.productDetails["PODS"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">MOISTURE</th>
                    <td>
                      {product.productDetails["MOISTURE"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">FOREIGN MATTERS</th>
                    <td>
                      {product.productDetails["FOREIGN MATTERS"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">DAMAGED</th>
                    <td>
                      {product.productDetails["DAMAGED"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">LOOSE SEEDS</th>
                    <td>
                      {product.productDetails["LOOSE SEEDS"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">LOADABLE</th>
                    <td>
                      {product.productDetails["LOADABLE"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">PAYMENT TERMS</th>
                    <td>
                      {product.productDetails["PAYMENT TERMS"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">PACKING</th>
                    <td>
                      {product.productDetails["PACKING"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">DELIVERY TIME</th>
                    <td>
                      {product.productDetails["DELIVERY TIME"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">MINIMUM ORDER QUANTITY</th>
                    <td>
                      {product.productDetails["MINIMUM ORDER QUANTITY"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">UNIT PRICE</th>
                    <td>
                      {product.productDetails["UNIT PRICE"] ||
                        "No extra details available"}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Sannam;
