import React from "react";
import "./css/chilliDecription.css";

const ChilliDescription = ({ descriptions }) => {
  console.log("desc", descriptions.productName);
  let component = "";
  switch (descriptions.productName) {
    case "Dry Red Chillies - 273 WRINKLE":
      component = <WrinkleChilli />;
      break;
    case "Dry Red Chillies - SANNAM":
      component = <SannamChilli />;
      break;
    case "Dry Red Chillies - TEJA":
      component = <TejaChilli />;
      break;
    case "Dry Red Chillies - BYADGI":
      component = <ByadgiChilli />;
      break;
    case "Ramnad Mundu Chilli":
      component = <RamnadMunduChilli />;
      break;
    default:
      component = <p>No description found for this product.</p>;
  }
  return component;
};

const WrinkleChilli = () => {
  return (
    <div id="chilliDescription" className="chilli-description-container">
      <div className="chilli-description-grid">
        <div className="image-container">
          <img
            alt="273 Wrinkle Dry Red Chili"
            src="https://5.imimg.com/data5/QX/CD/MY-37733893/wrinkled-273-chilli.png"
            className="chilli-image"
            style={{ borderRadius: "10%" }}
          />
        </div>
        <div className="chilli-details">
          <p className="intro-paragraph">
            The <strong>273 Wrinkle Dry Red Chili</strong> is a type of chili
            known for its distinct wrinkled appearance, medium to high pungency,
            and bright red color. Besides its culinary uses, it offers several
            health benefits due to its active compounds, especially capsaicin.
            Here are some of the health benefits associated with dry red
            chillies like the 273 Wrinkle variety:
          </p>
          <h5 className="section-title">Health Benefits:</h5>
          <div className="benefit-section">
            <h4>Rich in Antioxidants</h4>
            <p>
              Dry red chillies contain high levels of antioxidants, such as
              vitamin C and beta-carotene, which help combat oxidative stress
              and protect cells from damage.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Supports Heart Health</h4>
            <p>
              Regular consumption of red chillies can improve cardiovascular
              health by reducing cholesterol levels, lowering blood pressure,
              and preventing blood clotting, thus reducing the risk of heart
              disease.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Improves Digestive Health</h4>
            <p>
              Capsaicin promotes the secretion of digestive fluids, helping the
              stomach and intestines process food better. This can improve
              digestion and prevent digestive disorders like bloating and
              indigestion.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SannamChilli = () => {
  return (
    <div id="chilliDescription" className="chilli-description-container">
      <div className="chilli-description-grid">
        <div className="image-container">
          <img
            alt="Dry Red Chillies - SANNAM"
            src="https://5.imimg.com/data5/LX/VD/MY-37733893/334-2fs4-2fsannam-chilli-500x500.png"
            className="chilli-image"
            style={{ borderRadius: "10%" }}
          />
        </div>
        <div className="chilli-details">
          <p className="intro-paragraph">
            <strong>Sannam Dry Red Chillies</strong> also known as S4 Sannam or
            Guntur Sannam, are a popular variety of Indian red chili known for
            their moderate heat, deep red color, and pungent flavor. Like other
            dry red chillies, Sannam chillies offer several health benefits,
            primarily due to the presence of capsaicin, vitamins, and other
            bioactive compounds. Here are some of the health benefits associated
            with Sannam Dry Red Chillies:
          </p>
          <h5 className="section-title">Health Benefits:</h5>
          <div className="benefit-section">
            <h4>Rich in Vitamins and Minerals</h4>
            <p>
              Sannam chillies are an excellent source of vitamins such as
              vitamin C and vitamin A. Vitamin C boosts the immune system, helps
              in the absorption of iron, and promotes skin health. Vitamin A
              supports vision, skin, and immune function.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Supports Heart Health</h4>
            <p>
              Regular consumption of red chillies can improve cardiovascular
              health by reducing cholesterol levels, lowering blood pressure,
              and preventing blood clotting, thus reducing the risk of heart
              disease.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Promotes Healthy Skin</h4>
            <p>
              Vitamin C and antioxidants in Sannam chillies play a role in
              collagen production, which is vital for maintaining skin
              elasticity and preventing signs of aging.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TejaChilli = () => {
  return (
    <div id="chilliDescription" className="chilli-description-container">
      <div className="chilli-description-grid">
        <div className="image-container">
          <img
            alt="Dry Red Chillies - SANNAM"
            src="https://img2.exportersindia.com/product_images/bc-full/2022/8/4217344/teja-red-chilli-1661750176-6512161.jpeg"
            className="chilli-image"
            style={{ borderRadius: "10%" }}
          />
        </div>
        <div className="chilli-details">
          <p className="intro-paragraph">
            <strong>Teja Dry Red Chillies</strong>, one of the spiciest
            varieties from the Indian chili family, are known for their vibrant
            red color and extremely high pungency. These chillies are often used
            in Indian cuisine and exported worldwide for their heat and flavor.
            Along with their culinary use, Teja chillies offer several health
            benefits due to the presence of capsaicin, antioxidants, and
            essential vitamins. Here are the key health benefits associated with
            Teja Dry Red Chillies:
          </p>
          <h5 className="section-title">Health Benefits:</h5>
          <div className="benefit-section">
            <h4>Boosts Metabolism</h4>
            <p>
              The active compound capsaicin in Teja chillies can significantly
              increase metabolic rate, which promotes fat burning and helps in
              weight loss by increasing the body's heat production
              (thermogenesis)
            </p>
          </div>
          <div className="benefit-section">
            <h4>Pain Relief</h4>
            <p>
              Capsaicin is known for its analgesic (pain-relieving) properties
              and is often used in topical creams to treat pain from conditions
              like arthritis, muscle soreness, and neuropathy. Eating Teja
              chillies can trigger the release of endorphins, the body’s natural
              painkillers, helping to alleviate discomfort.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Aids in Weight Loss</h4>
            <p>
              The thermogenic effect of capsaicin not only boosts metabolism but
              also reduces appetite, helping to curb overeating. Consuming Teja
              chillies can help you feel fuller for longer, aiding in weight
              management and fat loss.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ByadgiChilli = () => {
  return (
    <div id="chilliDescription" className="chilli-description-container">
      <div className="chilli-description-grid">
        <div className="image-container">
          <img
            alt="Dry Red Chillies - SANNAM"
            src="https://img2.exportersindia.com/product_images/bc-full/2022/8/4217344/teja-red-chilli-1661750176-6512161.jpeg"
            className="chilli-image"
            style={{ borderRadius: "10%" }}
          />
        </div>
        <div className="chilli-details">
          <p className="intro-paragraph">
            <strong>Byadgi Dry Red Chillies</strong> are a famous variety of
            chillies from the Indian state of Karnataka, known for their deep
            red color and mild heat. They are commonly used in the preparation
            of spice blends like Kashmiri chili powder due to their bright color
            and relatively low pungency. Despite being milder in heat compared
            to other varieties, Byadgi chillies still offer numerous health
            benefits thanks to their content of capsaicin, vitamins, and
            antioxidants. Here are the key health benefits associated with
            Byadgi Dry Red Chillies:
          </p>
          <h5 className="section-title">Health Benefits:</h5>
          <div className="benefit-section">
            <h4>Boosts Immune System</h4>
            <p>
              The vitamin C content in Byadgi chillies enhances immune function
              by stimulating the production of white blood cells, which protect
              the body against infections. Regular consumption can help boost
              immunity and reduce the duration of colds and other illnesses.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Weight Management</h4>
            <p>
              Capsaicin, even in moderate amounts as found in Byadgi chillies,
              can boost metabolism and help burn more calories by increasing the
              body's temperature. This can aid in weight loss by enhancing fat
              burning and suppressing appetite.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Helps Maintain Eye Health</h4>
            <p>
              Byadgi chillies are rich in vitamin A and beta-carotene, both of
              which are essential for maintaining good vision and eye health.
              Regular consumption of these chillies can help reduce the risk of
              age-related macular degeneration and other vision problems
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const RamnadMunduChilli = () => {
  return (
    <div id="chilliDescription" className="chilli-description-container">
      <div className="chilli-description-grid">
        <div className="image-container">
          <img
            alt="Ramnad Mundu Chilli"
            src="https://www.maatrubhumi.com/wp-content/uploads/2022/04/mundu-chilli.jpg"
            className="chilli-image"
            style={{ borderRadius: "10%" }}
          />
        </div>
        <div className="chilli-details">
          <p className="intro-paragraph">
            <strong>Ramanathapuram Mundu Chilli</strong> a variety from the
            Ramanathapuram district in Tamil Nadu, is known for its distinct
            flavor and moderate heat. Here are some potential health benefits
            associated with consuming Mundu Chilli:
          </p>
          <h5 className="section-title">Health Benefits:</h5>
          <div className="benefit-section">
            <h4>Rich in Vitamins</h4>
            <p>
              Mundu chilies are a good source of vitamins, particularly vitamin
              C, which helps boost the immune system, promotes skin health, and
              aids in wound healing.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Heart Health:</h4>
            <p>
              Regular consumption of chilies can help improve heart health by
              reducing blood cholesterol, triglycerides, and platelet
              aggregation, and increasing the body's ability to dissolve fibrin,
              which prevents blood clots.
            </p>
          </div>
          <div className="benefit-section">
            <h4>Improves Circulation</h4>
            <p>
              The heat from capsaicin can stimulate circulation, which may
              promote overall cardiovascular health and improve oxygen supply
              throughout the body
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChilliDescription;
