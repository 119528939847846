import axios from "axios";
import { toast } from "react-toastify";

export const sendEmail = async (
  name,
  email,
  phone,
  chilli_type,
  quality,
  quantity,
  payment_terms,
  shipping_terms,
  message
) => {
  try {
    const response = await axios.post(
      "http://localhost:4041/vsrMails/addVSRMails",
      {
        name: name || "-",
        recipient: email || "-",
        phone: phone || "-",
        chilli_type: chilli_type || "-",
        quality: quality || "-",
        quantity: quantity || "-",
        payment_terms: payment_terms || "-",
        shipping_terms: shipping_terms || "-",
        subject: "New Mail From Customer",
        message: message || "-",
      }
    );

    if (response.data.mStatus === 200) {
      console.log(response.data);
      console.log("Email sent successfully!");
      return toast.success("Email sent successfully!", {
        position: "top-right", // can be changed to 'bottom-left', etc.
        autoClose: 3000, // auto close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.error("Failed to send email: " + response);
    }
  } catch (error) {
    console.error("ERROR: " + error.message);
  }
};
