import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Button, Row, Col } from "reactstrap";
import "./css/gallery.css";
import Sannam from "./products/Sannam";
import FormComponent from "./form";
import ChilliDescription from "./chilliDescription";
export const Gallery = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [formComponentClicked, setFormComponentClicked] = useState(false);
  // const [descriptionClicked, setDescriptionClicked] = useState(false);
  const [descriptionClicked, setDescriptionClicked] = useState({
    clicked: false,
    productName: "",
  });
  const [productName, setProductName] = useState("");

  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setClicked(true);
    setFormComponentClicked(false);
  };

  const handlePlaceOrder = (productName) => {
    setProductName(productName);
    setFormComponentClicked(true);
    setClicked(false);
  };

  const handleDescriptionClick = (productName) => {
    setDescriptionClicked({
      clicked: true,
      productName: productName,
    });
    setClicked(false);
    setFormComponentClicked(false);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <h5>
            Explore our collection of premium dry red chillies known for their
            unique flavors and spice levels.
          </h5>
        </div>
        <Row>
          {props.data.map((item, index) => (
            <Col xs="12" sm="6" md="4" lg="3" className="mb-4" key={index}>
              <Card className="gallery-card">
                <div className="image-container">
                  <img
                    alt={item.title}
                    src={item.src}
                    className="gallery-image"
                  />
                </div>
                <CardBody>
                  <CardTitle tag="h5" className="gallery-title">
                    {item.title}
                  </CardTitle>
                  <Button
                    style={{ backgroundColor: "maroon" }}
                    color="primary"
                    className="gallery-button page-scroll"
                    href="#products"
                    //className="page-scroll"
                    onClick={() => handleViewDetails(item)}
                  >
                    Details
                    {/* <i className="fa fa-money-check-dollar-pen"></i> */}
                  </Button>{" "}
                  <Button
                    color="primary"
                    className="gallery-button page-scroll"
                    href="#chilliDescription"
                    //className="page-scroll"
                    onClick={() => handleDescriptionClick(item.title)}
                  >
                    Descriptions
                    {/* <i className="fa fa-book"></i> */}
                  </Button>{" "}
                  <Button
                    color="success"
                    href="#form"
                    className="page-scroll"
                    //className="gallery-button"
                    onClick={() => handlePlaceOrder(item.title)}
                  >
                    {/* <i className="fa fa-cart-arrow-down"></i> */}
                    Order
                  </Button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <br></br>{" "}
      <div style={{ marginTop: "0%" }}>
        {clicked && <Sannam product={selectedItem} />}
        {formComponentClicked && (
          <FormComponent
            productName={productName}
            setFormComponentClicked={setFormComponentClicked}
          />
        )}
        {!clicked && !formComponentClicked && descriptionClicked.clicked && (
          <ChilliDescription descriptions={descriptionClicked} />
        )}
      </div>
    </div>
  );
};
