import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import "./css/form.css"; // Import the custom CSS for styling
import { sendEmail } from "./API/apiCalls";
import { toast } from "react-toastify";

const FormComponent = ({ productName, setFormComponentClicked }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    chilli_type: productName,
    quality: "",
    quantity: "",
    payment_terms: "",
    shipping_terms: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name) {
      return toast.error("Name Should Not Be Empty!!", {
        position: "top-right", // can be changed to 'bottom-left', etc.
        autoClose: 3000, // auto close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!formData.email) {
      return toast.error("Email Should Not Be Empty!!", {
        position: "top-right", // can be changed to 'bottom-left', etc.
        autoClose: 3000, // auto close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!formData.phone) {
      return toast.error("Phone Number Should Not Be Empty!!", {
        position: "top-right", // can be changed to 'bottom-left', etc.
        autoClose: 3000, // auto close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // Add your form submission logic here
    sendEmail(
      formData.name,
      formData.email,
      formData.phone,
      formData.chilli_type,
      formData.quality,
      formData.quantity,
      formData.payment_terms,
      formData.shipping_terms,
      formData.message
    );
  };

  return (
    <>
      <div id="form" style={{ marginTop: "0%" }} className="form-container">
        <Form className="form-content">
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="name"
                >
                  Name
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={(e) => handleChange(e)}
                  placeholder="Name"
                  required={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="email"
                >
                  Email
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="phone"
                >
                  Phone
                </Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <br></br>

          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="chilli_type"
                >
                  Chilli Type
                </Label>
                <Input
                  type="text"
                  name="chilli_type"
                  id="chilli_type"
                  placeholder="Chilli Type"
                  value={formData.chilli_type}
                  onChange={(e) => handleChange(e)}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="quality"
                >
                  Quality
                </Label>
                <Input
                  style={{ height: "30px", width: "100%" }}
                  type="select"
                  name="quality"
                  id="quality"
                  value={formData.quality}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Quality</option>
                  <option value="Best Quality">Best Quality</option>
                  <option value="Medium Best Quality">
                    Medium Best Quality
                  </option>
                  <option value="Medium Quality">Medium Quality</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="quantity"
                >
                  Quantity
                </Label>
                <Input
                  type="text"
                  name="quantity"
                  id="quantity"
                  placeholder="Quantity"
                  value={formData.quantity}
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <br></br>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="payment_terms"
                >
                  Payment Terms
                </Label>
                <Input
                  style={{ height: "30px", width: "100%" }}
                  type="select"
                  name="payment_terms"
                  id="quality"
                  value={formData.payment_terms}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Payment Terms</option>
                  <option value="DP">DP</option>
                  <option value="TT">TT</option>
                  <option value="LC">LC</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="shipping_terms"
                >
                  Shipping Terms
                </Label>
                <Input
                  style={{ height: "30px", width: "100%" }}
                  type="select"
                  name="shipping_terms"
                  id="shipping_terms"
                  value={formData.shipping_terms}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select Shipment Terms</option>
                  <option value="FOP">FOP</option>
                  <option value="CIF">CIF</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  style={{
                    color: "black",
                    fontStyle: "bold",
                    fontSize: "15px",
                  }}
                  for="message"
                >
                  Message
                </Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
            }}
          >
            <Button
              color="success"
              type="submit"
              onClick={handleSubmit}
              className="submit-btn"
            >
              Submit
            </Button>
            <Button
              type="submit"
              color="danger"
              onClick={() => setFormComponentClicked(false)}
              className="submit-btn"
              style={{ marginLeft: "5px" }} // Adjust left margin instead
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default FormComponent;
