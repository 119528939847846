import React from "react";

function Certification() {
  return (
    <div
      id="certification"
      style={{ backgroundColor: "lightblue" }}
      className="text-center"
    >
      <h2>Accolades & Accreditation</h2>
      <p>
        Where Every Certification Speaks of Unmatched Quality and Global Trust
      </p>
      <div className="container">
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center", // Center the items horizontally
            alignItems: "center", // Align items vertically
            marginTop: "2%",
          }}
        >
          <div style={{ textAlign: "center", margin: "0 20px" }}>
            <img
              src="https://cdn-ilakblb.nitrocdn.com/NOwWlssTpIQyuGEHUoAfImePvabguBeF/assets/images/optimized/rev-84c4c2e/surplusfintech.com/wp-content/uploads/2024/04/IEC.png"
              alt="IE Code Logo"
              style={{
                height: "100px",
                width: "150px",
                borderRadius: "15%",
                marginBottom: "10px",
              }}
            />
            <h3>AAYFV2172L</h3>
          </div>
          <div style={{ textAlign: "center", margin: "0 20px" }}>
            <img
              src="https://businesswindo.com/info-cafe/uploads/594cccc075c0a324gst-updates.jpg"
              alt="GST Logo"
              style={{
                height: "100px",
                width: "150px",
                borderRadius: "15%",
                marginBottom: "10px",
              }}
            />
            <h3>33AAYFV2172L1ZI</h3>
          </div>
        </div>
        {/* <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Accolades & Accreditation</h2>
        </div> */}
        {/* Center the first row */}

        {/* Second row remains unchanged */}
        <div style={{ marginTop: "8%" }} className="row">
          <div className="col-xs-6 col-md-3">
            <img
              src="https://logowik.com/content/uploads/images/fssai8862.jpg"
              alt="FSSAI Logo"
              style={{
                height: "100px",
                width: "150px",
                borderRadius: "15%",
                marginBottom: "10px",
              }}
            />
            <h3>12424997000102</h3>
          </div>
          <div className="col-xs-6 col-md-3">
            <img
              src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,fit=crop/mp86l72XENIX7zXP/msme-m7VpP5D0opHLqDLl.jpg"
              alt="MSME Logo"
              style={{
                height: "100px",
                width: "150px",
                borderRadius: "15%",
                marginBottom: "10px",
              }}
            />
          </div>
          <div className="col-xs-6 col-md-3">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3s4YajQkill6DumM6I8HvU_BU8bNpwwn4ig&s"
              alt="Spices Board Logo"
              style={{
                height: "100px",
                width: "150px",
                borderRadius: "15%",
                marginBottom: "10px",
              }}
            />
          </div>
          <div className="col-xs-6 col-md-3">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc4lde_PAaMi4QoaQeWCMq5yTy8DH8a1f7LQ&s"
              alt="APEDA Logo"
              style={{
                height: "100px",
                width: "150px",
                borderRadius: "15%",
                marginBottom: "10px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certification;
